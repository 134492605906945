<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Quản lý giá vốn</h3>
      </div>
      <div class="card-title">
        <div class="dropdown dropdown-inline mr-2">
          <button type="button" @click="openImportModal" class="btn btn-light-primary font-weight-bolder">
            <span class="svg-icon svg-icon-md">
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <path
                    d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                    fill="#000000" opacity="0.3"></path>
                  <path
                    d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                    fill="#000000"></path>
                </g>
              </svg>
            </span>Import
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="col-sm-12	col-md-6 col-lg-4	col-xl-2 mt-5">
            <label>Trung tâm</label>
            <el-select v-model="query.center_id" clearable filterable collapse-tags placeholder="Chọn trung tâm">
              <el-option v-for="item in centers" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-12	col-md-6 col-lg-4	col-xl-2 mt-5">
            <label>Khóa học</label>
            <el-input type="text" v-model="query.keyword" placeholder="Tìm ID, tên khóa học"></el-input>
          </div>
          <div class="col-sm-12	col-md-6 col-lg-4	col-xl-2 mt-5">
            <label>Tháng áp dụng</label>
            <el-date-picker v-model="show_date" type="month" placeholder="Chọn tháng" @change="saveRange()">
            </el-date-picker>
          </div>
          <div class="col-sm-12	col-md-6	col-lg-4	col-xl-2 mt-5">
            <label style="color:white">Giảng viên</label>
            <div class="d-flex justify-content-between align-items-end">
              <button class="btn btn-primary font-weight-bold"
                      :class="isBusy ? 'spinner spinner-white spinner-left' : ''"
                      :disabled="isBusy"
                      @click="searchData">
                  <i class="fa fa-search"></i> Tìm kiếm
              </button>
            </div>
          </div>
        </div>
        <div class="example-preview table-responsive overflow-auto mt-3">
          <table :aria-busy="isBusy" class="table table-hover table-bordered table-vertical-center b-table"
            style="text-align: center">
            <thead>
              <tr>
                <th scope="col" class="min-w-100px">ID Khóa học</th>
                <th scope="col" class="min-w-100px">Trung tâm</th>
                <th scope="col" class="min-w-100px">Khóa học</th>
                <th scope="col" class="min-w-100px">NVL Sách</th>
                <th scope="col" class="min-w-100px">NVL Chứng chỉ</th>
                <th scope="col" class="min-w-120px">NVL Thưởng đầu ra</th>
                <th scope="col" class="min-w-120px">NVL Liên hoan KG/KT</th>
                <th scope="col" class="min-w-120px">NVL Văn phòng phẩm</th>
                <th scope="col" class="min-w-100px">NC Giáo viên</th>
                <th scope="col" class="min-w-100px">NC Khai giảng & Kết thúc</th>
                <th scope="col" class="min-w-120px">NC Demo</th>
                <th scope="col" class="min-w-120px">NC Quà kết thúc</th>
                <th scope="col" class="min-w-120px">NC Trợ giảng</th>
                <th scope="col" class="min-w-120px">Tháng áp dụng</th>
              </tr>
            </thead>
            <tbody v-if="isBusy">
              <tr role="row" class="b-table-busy-slot">
                <td colspan="16" role="cell" class="">
                  <div class="text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="paginate.data.length > 0">
              <tr v-for="(item, index) in paginate.data" :key="index">
                <td>{{ item.course_id }}</td>
                <td>{{ item.center_name }}</td>
                <td>{{ item.course_name }}</td>
                <td>{{ formatPrice(item.book_material , 'đ') }}</td>
                <td>{{ formatPrice(item.certificate_material , 'đ') }}</td>
                <td>{{ formatPrice(item.output_bonus_material , 'đ') }}</td>
                <td>{{ formatPrice(item.party_material , 'đ') }}</td>
                <td>{{ formatPrice(item.stationery_material , 'đ') }}</td>
                <td>{{ formatPrice(item.teacher_labor , 'đ') }}</td>
                <td>{{ formatPrice(item.open_end_labor , 'đ') }}</td>
                <td>{{ formatPrice(item.demo_labor , 'đ') }}</td>
                <td>{{ formatPrice(item.end_gift_labor , 'đ') }}</td>
                <td>{{ formatPrice(item.teaching_assistant_labor , 'đ') }}</td>
                <td>{{ item.date_apply | formatMonthYear }}</td>
            </tr>
            </tbody>
            <tbody v-else-if="paginate.data == 0">
              <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
            </tbody>
          </table>
        </div>
        <div class="position-absolute" style="right:40px">
          Tổng số bản ghi: <b style="color:#3699FF">{{ paginate.total }}</b>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate v-model="paginate.page" :page-count="paginate.last_page" :page-range="3" :margin-pages="1"
        :click-handler="clickCallback" :prev-text="'Trước'" :next-text="'Sau'"
        :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'" :next-link-class="'next-link-item'"
        :prev-link-class="'prev-link-item'" :prev-class="'page-link'" :next-class="'page-link'" :page-class="'page-item'">
      </paginate>
    </div>

    <!-- dialog import -->
    <el-dialog :class="!costPriceError ? '' : 'cost-price'" title="Import giá vốn" :visible.sync="isOpenImport" :width="!costPriceError ? '30%' : '75%'" :before-close="handleClose">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent v-if="!costPriceError">
      <div>
        <div>
          <span class="d-block">Bước 1: Tải file mẫu</span>
          <button class="mt-2 btn btn-primary font-weight-bold mr-2" @click="downloadFile()">
            <i class="fas fa-download"></i> Tải xuống
          </button>
        </div>
        <div class="mt-5">
          <span class="d-block">Bước 2: Tải file excel lên hệ thống</span>
          <div class="file-input">
            <ValidationProvider name="type_task" rules="required|max:30" v-slot="{ errors,classes }"
                                vid="type_task">
              <input
                  ref="fileCostPrice"
                  type="file"
                  name="file-input-team"
                  id="file-input-team"
                  class="file-input__input"
                  @change="getDataFile"
                  :disabled="disableImport"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
              <label class="file-input__label mt-2" for="file-input-team">
                <span><i class="fas fa-upload mr-2" style="color: #000000"></i>Tải lên</span></label
              >
            </ValidationProvider>
            <div v-if="fileDataImport">
              <p class="badge badge-secondary  p-2 ">{{ fileDataImport.name }}</p>
              <i class="fas fa-trash ml-3 text-danger  " style="font-size: 13px; cursor: pointer"
                 @click="deleteFileExcel()"></i>
            </div>
            <div v-else class="text-danger mt-2">
              Vui lòng chọn file
            </div>
          </div>
        </div>
      </div>
      </form>
      <div v-else>
          <p>Hệ thống phát hiện có lỗi trong quá trình import dữ liệu:</p>
          <div class="mt-5 table-responsive">
            <table class="table table-bordered  table-vertical-center ">
              <thead>
              <tr class="" style="background-color: #F8F8F8 ; font-size: 13px">
                <th>ID khóa học</th>
                <th>NVL_Sách</th>
                <th>NVL_Chứng chỉ</th>
                <th>NVL_Thưởng đầu ra</th>
                <th>NVL_Liên hoan KG/KT</th>
                <th>NVL_Văn phòng phẩm</th>
                <th>NC_Giáo viên</th>
                <th>NC_Khai giảng & Kết thúc</th>
                <th>NC_Demo</th>
                <th>NC_Quà kết thúc</th>
                <th>NC_Trợ giảng</th>
                <th>Tháng áp dụng</th>
                <th>Năm áp dụng</th>
                <th>Nội dung Lỗi</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(error,index) in errorCostPrices" :key="index">
                <td class="">{{ error.costprice.course_id }}</td>
                <td class="">{{ error.costprice.book_material ? formatPrice(error.costprice.book_material , 'đ') : '0'}}</td>
                <td class="">{{ error.costprice.certificate_material ? formatPrice(error.costprice.certificate_material , 'đ') : '0'}}</td>
                <td class="">{{ error.costprice.output_bonus_material ? formatPrice(error.costprice.output_bonus_material , 'đ') : '0'}}</td>
                <td class="">{{ error.costprice.party_material ? formatPrice(error.costprice.party_material , 'đ') : '0'}}</td>
                <td class="">{{ error.costprice.stationery_material ? formatPrice(error.costprice.stationery_material , 'đ') : '0'}}</td>
                <td class="">{{ error.costprice.teacher_labor ? formatPrice(error.costprice.teacher_labor , 'đ') : '0'}}</td>
                <td class="">{{ error.costprice.open_end_labor ? formatPrice(error.costprice.open_end_labor , 'đ') : '0'}}</td>
                <td class="">{{ error.costprice.demo_labor ? formatPrice(error.costprice.demo_labor , 'đ') : '0'}}</td>
                <td class="">{{ error.costprice.end_gift_labor ? formatPrice(error.costprice.end_gift_labor , 'đ') : '0'}}</td>
                <td class="">{{ error.costprice.teaching_assistant_labor ? formatPrice(error.costprice.teaching_assistant_labor , 'đ') : '0'}}</td>
                <td class="">{{ error.costprice.month }}</td>
                <td class="">{{ error.costprice.year }}</td>
                <td>
                  <p class="text-danger" v-for="item in error.error" :key="item">
                    {{ item }}
                  </p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-secondary" @click="comeback()">Quay lại</button>
          </div>
        </div>
      </ValidationObserver>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-secondary mr-2" @click="isOpenImport = false">Hủy</button>
        <button  v-if="!costPriceError" class="btn btn-info" :disabled="loading" @click="previewImportExcel()">
            <p class="mb-0" v-if="!loading">
              Tiếp theo
            </p>
            <div v-else class="spinner-border text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
        </button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import { GET_CENTERS } from "../../../core/services/store/center/centers.module";
import {
  DOWNLOAD_COST_PRICE_DEMO,
  IMPORT_COST_PRICE,
  LIST_COST_PRICE
} from "@/core/services/store/costprice/costprice.module";
import moment from 'moment';
import {utils, read} from "xlsx";
export default {
  components: {
  },

  name: "CostPrice",
  data() {
    return {
      query: {
        center_id: [],
        keyword: '',
        date_apply: ''
      },
      show_date : '',
      centers: [],
      courses: [],
      isBusy: true,
      paginate: {
        data : null,
        total: 0,
        last_page: 0,
        page: 0,
        pageCount: 0
      },
      isOpenImport: false,
      fileDataImport: null,
      disableImport: false,
      importData: null,
      errorCostPrices: [],
      costPriceError: false,
      disableSave: false,
      loading: false,
      loading_list: false
    };
  },       
 
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý tài chính", route: "" },
      { title: "Quản lý giá vốn" },
    ]);
    await this.getCenters();
    await this.getListCostPrice();
  },
  computed: {},

  methods: {
    pushParamsUrl() {
      this.$router.push({
        name: 'cost-price',
        query: {
          page: this.paginate.page,
          ...this.query
        }
      })
    },
    searchData(){
      this.page = 1
      this.pushParamsUrl()
      this.getListCostPrice()
    },
    getListCostPrice(){
      this.isBusy = true;
      let payload = {};
      payload = {...this.query, page: this.paginate.page}
      // if(payload.center_ids.length == 0) {
        // payload = {...payload,center_ids:this.center_ids}
      // }
      return this.$store.dispatch(LIST_COST_PRICE,payload).then((res) => {
        if (!res.error) {
          this.paginate = {
            last_page : res.pagination.last_page,
            total : res.pagination.total,
            data : res.data
          }
          this.isBusy = false;
        }
      });
    },
    comeback() {
      this.costPriceError = false
      this.disableSave = false
    },
    previewImportExcel() {
      if (this.importData == null) {
        return
      }
      this.checkErrorDataExel()
      this.disableSave = true
      let is_error = false
      if (this.errorCostPrices.filter((error)=>error.error.length>0).length > 0) {
        this.costPriceError = true
        is_error = true
      }
      let payload = {
        data: {
          data :this.importData.map((d) => {
            return {
              index : d.index,
              course_id : d.course_id ?? null,
              book_material : d.book_material ?? null,
              certificate_material : d.certificate_material ?? null,
              output_bonus_material : d.output_bonus_material ?? null,
              party_material : d.party_material ?? null,
              stationery_material : d.stationery_material ?? null,
              teacher_labor : d.teacher_labor ?? null,
              open_end_labor : d.open_end_labor ?? null,
              demo_labor : d.demo_labor ?? null,
              end_gift_labor : d.end_gift_labor ?? null,
              teaching_assistant_labor : d.teaching_assistant_labor ?? null,
              month : d.month ?? null,
              year : d.year ?? null
            }
          }),
          is_error : is_error
        }
      }
      this.loading = true
      this.$store.dispatch(IMPORT_COST_PRICE, payload).then((data) => {
        if (!data.error) {
          this.$emit("getExam")
          this.$notify({
            title: 'Thành công',
            message: 'Import giá vốn thành công',
            type: 'success'
          });
          this.isOpenImport = false;
          this.deleteFileExcel();
          this.getListCostPrice()
        } else {
          this.$notify({
            title: 'Lỗi',
            message: `${data.message}`,
            type: 'error'
          });
        }
      }).catch((error) => {
        if(error.data.message == 'Error exist') {
          this.costPriceError = true
            let errorBE = error.data.data.map((d)=>{
               return {
                 index : d.index,
                 costprice : {
                   course_id : d.course_id ?? null,
                   book_material : d.book_material ?? null,
                   certificate_material : d.certificate_material ?? null,
                   output_bonus_material : d.output_bonus_material ?? null,
                   party_material : d.party_material ?? null,
                   stationery_material : d.stationery_material ?? null,
                   teacher_labor : d.teacher_labor ?? null,
                   open_end_labor : d.open_end_labor ?? null,
                   demo_labor : d.demo_labor ?? null,
                   end_gift_labor : d.end_gift_labor ?? null,
                   teaching_assistant_labor : d.teaching_assistant_labor ?? null,
                   month : d.month ?? null,
                   year : d.year ?? null
                 },
                 error : d.errors??false
               }
             })

            errorBE = errorBE.filter((error)=>error.error.length>0);
            this.errorCostPrices = this.errorCostPrices.filter((error)=>error.error.length>0);
            if(this.errorCostPrices.length > 0 && errorBE.length > 0) {
              const mergedArray = this.mergeArrays(this.errorCostPrices, errorBE, 'index');
              this.errorCostPrices = mergedArray;
            } 
            if(this.errorCostPrices.length <= 0 && errorBE.length > 0) {
              this.errorCostPrices = errorBE;
            }
        }
      }).finally(()=>{
        this.loading =false
      })
      this.errorCostPrices = this.errorCostPrices.filter((error)=>error.error.length>0)

    },
    mergeArrays(array1, array2, key) {
      const mergedArray = [];
      array1.forEach(obj1 => {
        const matchingObj2 = array2.find(obj2 => obj2[key] === obj1[key]);
        
        if (matchingObj2) {
          // Merge the two objects
          obj1.error = obj1.error.concat(matchingObj2.error)
          mergedArray.push(obj1);
        } else {
          // If no match found, add the object from array1 as is
          mergedArray.push(obj1);
        }
      });
      return mergedArray;
    },
    checkCostPriceError(costprice, error_unique) {
      let error = []
      let fieldErrors = ['course_id', 'book_material', 'certificate_material', 'output_bonus_material', 'party_material',
       "stationery_material","teacher_labor","open_end_labor","demo_labor","end_gift_labor","teaching_assistant_labor","month","year"].map(d => {
        let key = '';
        switch (d) {
          case ('course_id'): 
          key = 'ID khóa học';
          break;
          case ('book_material'): 
          key = 'Nguyên vật liệu Sách';
          break;
          case ('certificate_material'): 
          key = 'Nguyên vật liệu Chứng chỉ';
          break;
          case ('output_bonus_material'): 
          key = 'Nguyên vật liệu Thưởng đầu ra';
          break;
          case ('party_material'): 
          key = 'Nguyên vật liệu Liên hoan KG/KT';
          break;
          case ('stationery_material'): 
          key = 'Nguyên vật liệu Văn phòng phẩm';
          break;
          case ('teacher_labor'): 
          key = 'Nhân công Giáo viên';
          break;
          case ('open_end_labor'): 
          key = 'Nhân công Khai giảng & Kết thúc';
          break;
          case ('demo_labor'): 
          key = 'Nhân công Demo';
          break;
          case ('end_gift_labor'): 
          key = 'Nhân công Quà kết thúc';
          break;
          case ('teaching_assistant_labor'): 
          key = 'Nhân công Trợ giảng';
          break;
          case ('month'): 
          key = 'Tháng áp dụng';
          break;
          case ('year'): 
          key = 'Năm áp dụng';
          break;
        }
        if (costprice[d] === null || costprice[d] === undefined || costprice[d] === '') {
            return "Thiếu dữ liệu: " + key;
        } else {
          if ((d == 'course_id' || d == 'month' || d == 'year') && costprice[d] == 0) {
            return "Thiếu dữ liệu: " + key;
          }
        }
        
      }).filter((a) => a != undefined);
      error = [...fieldErrors]
      if(error_unique) {
        error = [...fieldErrors,error_unique]
      }
      return {index:costprice.index, costprice: costprice, error: error}
    },
    deleteFileExcel() {
      this.disableImport = false
      this.importData = null
      this.fileDataImport = null
      this.$refs.fileCostPrice.value = null;
    },
    checkErrorDataExel() {
      let input_uniques = [];
      this.errorCostPrices = this.importData.map((costprice, index) => {
        let error_unique;
        let unique = costprice.course_id + '-' + costprice.month + '-'+costprice.year;
        if(input_uniques.includes(unique)) {
          error_unique = 'File import có nhiều hơn 1 giá vốn của khóa học tại 1 thời điểm';
        }
        input_uniques.push(unique);
        return this.checkCostPriceError(costprice, error_unique);
      })
    },
    fileToJson(file) {
      return new Promise((res, rej) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader()
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result
          const wb = read(bstr, {type: 'binary'})
          /* Get first worksheet */
          const costPriceName = wb.SheetNames[0]
          const costPriceNameSheet = wb.Sheets[costPriceName]
          /* Convert array of arrays */
          const costprice = utils.sheet_to_json(costPriceNameSheet)
          let dataImport = costprice.map((d,index)=>{
            return {
              index : index,
              course_id : d['ID khóa học'] ?? null,
              book_material : d['NVL_Sách'] ?? null,
              certificate_material : d['NVL_Chứng chỉ'] ?? null,
              output_bonus_material : d['NVL_Thưởng đầu ra'] ?? null,
              party_material : d['NVL_Liên hoan KG/KT'] ?? null,
              stationery_material : d['NVL_Văn phòng phẩm'] ?? null,
              teacher_labor : d['NC_Giáo viên'] ?? null,
              open_end_labor : d['NC_Khai giảng & Kết thúc'] ?? null,
              demo_labor : d['NC_Demo'] ?? null,
              end_gift_labor : d['NC_Quà kết thúc'] ?? null,
              teaching_assistant_labor : d['NC_Trợ giảng'] ?? null,
              month : d['Tháng áp dụng'] ?? null,
              year : d['Năm áp dụng'] ?? null
            }
          })
          /* Update state */
          res(dataImport.filter((d) => d != []));
          //const header = data.shift()
        }
        reader.onerror = (e) => {
          rej(e);
        };
        reader.readAsBinaryString(file)
      });
    },
    async getDataFile(e) {
      if (e.target.files.length) {
        this.disableImport = true
        this.fileDataImport = e.target.files[0]
        this.importData = await this.fileToJson(this.fileDataImport)
        this.importData = this.importData.map((data) => {
          return {...data}
        })
      }
    },
    downloadFile(){
      this.$store.dispatch(DOWNLOAD_COST_PRICE_DEMO).then(() => {
      }).catch(() => {
      })
    },
    handleClose() {
      this.isOpenImport = false;
      this.deleteFileExcel();
    },
    openImportModal() {
      this.isOpenImport = true;
    },
    getCenters() {
      return this.$store.dispatch(GET_CENTERS, { is_edutalk: '1' }).then((res) => {
        if (!res.error) {
          for (let dataKey in res.data.data) {
            this.centers.push({
              id: res.data.data[dataKey].id,
              name: res.data.data[dataKey].name,
            });
          }
          this.centers.push({
            id: 233,
            name: "Sinh trắc Gen Z",
          });
        }
      });
    },
    clickCallback(obj) {
      this.paginate.page = obj
      this.$router.push({
        name: 'cost-price',
        query: {
          page: this.paginate.page,
          ...this.query
        }
      })
      this.getListCostPrice()
    },
    saveRange: function () {
      let start = this.show_date ? moment(this.show_date).format('YYYY-MM') : '';
      this.query.date_apply = start;
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  th, td {
    white-space: nowrap
  }
}
.file-input__input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  height: 34px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  padding: 7px 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  background-color: #FF9900;
}

input:disabled + label {
  background-color: #a6a6a6;
}
</style>
<style>
.el-dialog__header {
    border-bottom:1px solid#80808033 !important;
}
.cost-price .el-dialog {
  max-height: 700px;
  overflow: auto;
}
</style>
